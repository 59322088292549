const useQuestTask = () => {
	const { open } = useAppModals();
	const { games: gamesFilters } = useGetGameFiltersData({ immediate: false });
	const { handleOpenGame } = useOpenGame(open);
	const { data: questData } = useGetQuestData();
	const currentTask = computed(() => questData.value?.data?.dayInfo?.tasks?.[0]);
	const questGames = computed(() => currentTask.value?.action?.details?.games || gamesFilters.value || []);
	const taskCompleted = computed(() => !!currentTask.value?.isCompleted);
	const { execute: activeTaskDeposit } = useAsyncFetch({
		path: "/rest/player/balance/",
		method: "get",
		fetchOptions: {
			onResponse: ({ response }) => {
				if (!response._data.success) {
					return;
				}
				if (response._data.data.winnings < 1) {
					window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
				}
				window?.$cash?.$router?.push?.("/cash/deposit/");
			}
		},
		options: {
			immediate: false
		}
	});

	const { playMysteryBoxAudio } = useMysteryBoxAudios();

	const openQuestTask = () => {
		const { isCompleted, isEnded, isLocked, tasks } = questData.value?.data?.dayInfo || {};
		const activeTask = tasks?.find((task) => !task.isCompleted);

		if (isCompleted && isEnded && isLocked) {
			open("LazyOModalQuests");
			return;
		}

		if (
			!activeTask ||
			!activeTask!.action!.type ||
			(activeTask!.action!.type === "gamesList" && activeTask!.action!.details?.games?.length)
		) {
			open("LazyOModalRacesGames");
			return;
		}
		if (activeTask!.action!.type === "magicBox") {
			open("LazyOModalMysteryBox");
			playMysteryBoxAudio();
			return;
		}
		if (activeTask!.action!.type === "game" && activeTask!.action!.details?.game) {
			handleOpenGame(activeTask!.action!.details.game.slug);
			return;
		}
		if (activeTask!.action!.type === "deposit") {
			activeTaskDeposit();
		}
		open("LazyOModalQuests");
	};

	return { questData, questGames, currentTask, taskCompleted, openQuestTask };
};

export default useQuestTask;
